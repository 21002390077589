<template>
  <div class="base-detail-list-container" :class="getFlexDirection">
    <div class="base-detail-list-content" :id="$rt(Props.items.index)">
      <h1 class="detail-title" id="base-detail-list-title">{{ $rt(Props.items.title) }}</h1>

      <div v-if="Props.items.listType === 'alphaList' && Props.items.paragraphs" class="alpha-text">{{
        $rt(Props.items.paragraphs[0]) }}</div>
      <div class="detail-list">
        <!--      <ol v-if="Props.item.listType === 'number'" class="detail-list-text">
        <li v-for="item, index in Props.item.items" :key="index">{{ item }}</li>
      </ol>-->
        <ul class="detail-list-text" :class="getListClass">
          <li v-for="item, index in Props.items.items" :key="index">{{ $rt(item) }}</li>
        </ul>
        <div v-if="Props.items.listType === 'alphaGrid'" v-for="item, index in Props.items.section" class="alpha-list">
          <div class="alpha-header">{{ $rt(item.subheader) }}</div>
          <div class="alpha-text">{{ $rt(item.paragraphs[0]) }}</div>
        </div>

      </div>

    </div>

    <div v-if="Props.items.resourceUri.length === 1" class="base-detail-image">
      <img class="base-detail-list-img" :src="$rt(Props.items.resourceUri[0])" />
      <div class="base-detail-alt" v-if="Props.items.alt != null">
        <p class="base-detail-alt-bar">{{ $rt(Props.items.alt[0]) }}</p>
      </div>
    </div>

    <div v-if="Props.items.listType === 'alphaGrid'" class="image-grid-holder">
      <div v-for="item, index in Props.items.resourceUri" class="base-detail-image">
        <img class="base-detail-list-img" :src="$rt(Props.items.resourceUri[index])" />
        <!--      <div v-if="Props.item.alt != null">
          <p class="base-detail-alt-bar">{{ Props.item.alt[0] }}</p>
        </div>-->
      </div>
    </div>



  </div>
</template>
<script>
export default {
  props: [
    'items'
  ],
  computed: {
    Props () {
      return this.$props;
    },
    getListClass () {
      let listClass = '';
      switch (this.Props.items.listType) {
        case 'bullet':
          listClass = 'bullet';
          break;
        case 'number':
          listClass = 'number';
          break;
        case 'alphaList':
          listClass = 'alpha';
          break;
        default:
          listClass = '';
          break;
      }
      return listClass;
    },
    getFlexDirection () {
      return (!this.Props.items.isRight) ? 'reverse' : '';
    }
  }
};
</script>

<style scoped>
.reverse {
  flex-direction: row-reverse !important;
}

.bullet {
  list-style-type: disc;
}

.number {
  list-style-type: decimal;
}

.alpha {
  list-style-type: lower-alpha;
}

.base-detail-alt {
  display: flex;
  flex-direction: row;
  padding-top: 1rem;
  width: 100%;
}

.base-detail-alt-bar {
  font-size: 13px;
  line-height: 1.7em;
  font-weight: 400;
  padding-left: 1rem;
  border-left: 1px solid #d3cdc2;
  margin-top: 20px;
}

.base-detail-list-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 40px;
}

.base-detail-list-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 2;
}

#base-detail-list-title {
  align-self: center;
  margin-bottom: 15px;
  margin-top: 25px;
}

.detail-title {
  font-size: 35px;
  font-weight: 900;
}

.detail-list-text {
  font-size: 15px;
  list-style-type: decimal;
}

.detail-list {
  width: 90%;
  margin-top: 15px;
}

.base-detail-image {
  object-fit: cover;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 2;
  flex-direction: column;
}

.base-detail-list-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.alpha-list {
  margin-bottom: 10px;
}

.alpha-header {
  font-size: 16px;
  font-weight: bold;
}

.alpha-text {
  font-size: 15px;
  line-height: 1.5;
  margin-top: 6px;
}

.image-grid-holder {
  display: grid;
  grid-template-columns: 49% auto;
  column-gap: 20px;
  row-gap: 20px;
  max-width: 50%;
  flex: 2;
}

@media only screen and (max-width: 1200px) {
  .base-detail-list-container {
    display: flex;
    flex-direction: column-reverse !important;
  }

  .base-detail-list-img {
    width: 100%;
  }

  .detail-list {
    width: 100%;
  }
}

@media only screen and (max-width: 800px) {
  .base-detail-list-img {
    padding-left: 30px;
    padding-right: 30px;
  }

  .base-detail-list-content {
    padding-left: 20px;
    padding-right: 20px;
  }

  .detail-list-text {
    font-size: 14px;
  }
}
</style>