<template>
  <div class="base-detail-container" :id="$rt(items.title ? items.title : '') + items.index" :class="isFullWidth">
    <div class="base-detail-box" :id="getId">
      <h1 class="detail-title" v-show="items.title != null" id="base-detail-header" v-html="$rt(items.title ? items.title : '').replace(/[™®©]/g, '<sup>$&</sup>')"></h1>

      <div ref="detailParagraphs">
        <p class="detail-paragraph" id="base-detail-paragraphs" v-for="paragraph in items.paragraphs" v-html="$rt(paragraph).replace(/[™®©]/g, '<sup>$&</sup>')"></p>
      </div>
      <div v-if="items.isList">
        <p v-if="items.listTitle" class="base-detail-bullet-title">{{ $rt(items.listTitle) }}</p>
        <ul class="base-detail-bullet-items">
          <li class="base-detail-bullet-text" v-for="(item, index) in items.list" :key="index">
            {{ $rt(item) }}
          </li>
        </ul>
      </div>
      <BaseButton v-if="Props.items.isButton && $rt(Props.items.buttonPath) != null" :label="Props.items.buttonText"
        :buttonStyle="DetailStyle" :url="$rt(Props.items.buttonPath)" />
    </div>
    <div class="base-detail-media-box" :style="detailMediaContainer" v-if="items.isVideo">
      <iframe width="400px" height="400px" class="base-detail-video" allowfullscreen allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      :src="$rt(Array.isArray(items.videoUri) ? items.videoUri[0] : items.videoUri)"/>
    </div>
    <div class="base-detail-media-box" :style="detailMediaContainer" v-else>
      <div class="detail-image" v-for="(image, index) in items.resourceUri" :key="index">
        <img :style="handleImage()" class="base-detail-image" :src="$rt(image)" />
        <div v-if="items.alt != null" class="base-detail-alt">
          <p class="base-detail-alt-bar">{{ $rt(items.alt[index]) }}</p>
        </div>
      </div>
      <div v-if="items.isImageLinks" class="base-detail-image-link-box" style=" display: grid; grid-template-columns:
        1fr 1fr 1fr; gap: 5px;">
        <nuxt-link v-for="item in getImageLinks" class="base-detail-link" :to="$rt(item.url)"
          @mouseover="this.handleMouseOver(item)" @mouseleave="this.handleMouseLeave(item)">
          <p class="base-detail-link-img-text">{{ $rt(item.name) }}</p>
          <img class="base-detail-link-img" :src="$rt(item.resourceUri)" />
        </nuxt-link>
      </div>
    </div>

  </div>
</template>
<script>
import BaseButton from './BaseButton.vue';
export default {
  components: {
    BaseButton,
  },
  data () {
    return {
      detailStyle: {
        color: '#0089D0',
        hover: '#212121',
        isHover: false,
      },
    };
  },
  mounted () {
    if (this.$props.items.imageLinks) {
      this.$props.items.imageLinks.forEach((element) => {
        element.isHover = false;
      });
    }
  },
  props: ['items', 'width'],
  computed: {

    isFullWidth () {

      // console.log(this.items.resourceUri, ' uri');
      // return "";
      return (this.items.resourceUri === null) ? "bd-full-width" : "" ;

    },

    getId () {
      if (this.Props.items.index) {
        return this.$rt(this.Props.items.index)
      } else {
        return this.$rt(this.Props.items.title)
      }
    },
    Props () {
      return this.$props;
    },
    DetailStyle () {
      return {
        color: '#0089D0',
        hover: '#212121',
      };
    },

    getImageLinks () {
      return this.$props.items.imageLinks;
    },
    detailTextContainer () {
      if (this.$props.items.isRight) {
        return {
          'grid-column': '1/2',
          'grid-row': '1/2',
        };
      } else {
        return {
          'grid-column': '2/3',
          'grid-row': '1/2',
        };
      }
    },
    detailMediaContainer () {
      if (this.$props.items.isRight) {
        return {
          'grid-column': '2/3',
          'grid-row': '1/2',
        };
      } else {
        return {
          'grid-column': '1/2',
          'grid-row': '1/2',
        };
      }
    },
  },
  methods: {
    getItemIndex (item) {
      return  0;
    },
    handleImage () {
      if (this.$props.width != undefined) {
        return {
          width: this.$props.width,
        };
      } else {
        return;
      }
    },
    handleMouseOver (item) {
      item.isHover = true;
      this.isHover = true;
    },
    handleMouseLeave (item) {
      item.isHover = false;
      this.isHover = false;
    }
  },

};
</script>
<style scoped>
.base-detail-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  justify-content: center;
  width: 100%;
  padding-left: 40px;
  padding-right: 40px;
}

.bd-full-width {
   display: block;
}

.base-detail-media-box {
  float: right;
}
.base-detail-video-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.base-detail-box {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.base-detail-bullet-items {
  padding-left: 1rem;
}

.base-detail-bullet-title {
  padding-bottom: 1rem;
  font-size: 15px;
}

.base-detail-bullet-text {
  padding-bottom: 1rem;
  font-size: 15px;
}

#base-detail-paragraphs {
  white-space: break-spaces;
  line-height: 1.7em;
}

#base-detail-header {
  align-self: center;
  margin-bottom: 15px;
  padding-bottom: 1rem;
}

.base-detail-alt {
  display: flex;
  flex-direction: row;
  padding-top: 1rem;
}

.base-detail-alt-bar {
  font-size: 13px;
  line-height: 1.7em;
  font-weight: 400;
  padding-left: 1rem;
  border-left: 1px solid #d3cdc2;
  margin-top: 20px;
}

.base-detail-video {
  width: 100%;
}

.base-detail-image {
  width: 100%;
}

.detail-title {
  font-size: 35px;
  font-weight: 900;
  margin: 20px;
  width: 100%;

}

.detail-paragraph {
  font-size: 15px !important;
  line-height: 1.8 !important;
  padding-bottom: 20px;
}

.detail-image {
  margin: 4px;
}

@media only screen and (max-width: 750px) {

  .base-detail-container {
    display: flex;
    flex-direction: column-reverse !important;
  }

  .base-detail-image {
    width: 100%;
    margin-bottom: 3px;
  }

  .base-detail-video {
    max-width: 500px;
  }

  .base-detail-media-box,
  .base-detail-box {

    display: flex;
    flex-direction: column !important;


  }

  .detail-paragraph {
    font-size: 15px;
    margin: 0px 20px 20px 0px !important;
  }


  .detail-title {
    font-size: 18px !important;
    font-weight: 900;
    margin: 20px;
    width: 100%;
  }

  .detail-image {
    margin: 10% !important;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .base-detail-container {
    margin-top: 0px !important;
    padding-top: 10px !important;
  }


}

@media only screen and (max-width: 500px) {

  .detail-image {
    margin: 0px !important;
  }

  .detail-title {
    margin: 0;
    padding-bottom: 0 !important;
    font-size: 26px !important;

  }

  .detail-paragraph {
    padding-bottom: 0 !important;
    padding-right: 0 !important;
    margin-right: 0 !important;
  }

  .base-detail-container {
    padding-top: 0px !important;
    padding-left: 30px;
    padding-right: 30px;
  }



}

.base-detail-image-link {
  width: 100%;
  height: 100%;
}

.base-detail-link-img {
  width: 100%;
  height: 100%;
  opacity: 1;
}

.base-detail-link-img-text {
  position: relative;
  top: 20%;
  left: 10%;
  color: #FFF;
  font-weight: bold;
  font-style: italic;
  font-size: 17px;
  text-decoration: underline #fff;
  margin: 0;
  padding: 0;
}


.base-detail-link-img:hover {
  width: 100%;
  height: 100%;
}

.base-detail-link:hover {
  opacity: 0.8;
}
.button-btn {
  background-color: rgb(0, 137, 208);
  cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    width: 180px;
    padding: 10px;
    line-height: 1.2;
}


/* .base-detail-image-link-box {
  grid-template-rows: 100px 100px 100px;
} */
</style>
